<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :href="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10" class="pt-1">
                    <span class="headline font-weight-medium">Consumo Interno</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="pt-0">
                    <v-card>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="2">
                                    <v-menu
                                        v-model="menuFrom"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="filters.from"
                                                label="Desde"
                                                readonly
                                                clearable
                                                v-on="on"
                                                outlined
                                                hide-details                         
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="filters.from" 
                                            @input="menuFrom = false"
                                            no-title
                                            locale="es">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="2">
                                    <v-menu
                                        v-model="menuUntil"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="filters.until"
                                                label="Hasta"
                                                readonly
                                                clearable
                                                v-on="on"
                                                outlined
                                                hide-details                         
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="filters.until" 
                                            @input="menuUntil = false"
                                            no-title
                                            locale="es">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="3">
                                    <v-autocomplete
                                        v-model="filters.product_id"
                                        :items="products"
                                        label="Producto"
                                        outlined
                                        item-text="description"
                                        item-value="product_id"
                                        clearable
                                        hide-details="auto"
                                        :loading="isLoading"
                                        :search-input.sync="search_product"
                                        hide-no-data
                                        hide-selected
                                        placeholder="Ingrese el producto a buscar"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="3">
                                    <v-autocomplete
                                        v-model="filters.area_id"
                                        :items="areas"
                                        label="Área"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        clearable
                                        hide-details="auto"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn color="blue"
                                        dark
                                        @click="searchMovement">
                                        Buscar
                                    </v-btn>
                                    <v-btn color="green"
                                        dark
                                        class="ml-1"
                                        :loading="processing"
                                        @click="downloadMovement">
                                        Descargar
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
                
            </v-row>
            <v-row>
                <v-col cols="12" class="pt-1">
                    <v-card>
                        <v-data-table
                            :headers="headers"
                            :items="movements"
                            :items-per-page="10"
                            class="elevation-1 w-table"
                            height="57vh"
                            fixed-header
                            :loading="loading"
                            :options.sync="options"
                            :server-items-length="paginated.total"
                            loading-text="Loading... Please wait"
                        >
                            <template v-slot:top>
                                <span  class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">Todas los consumos internos</span>
                                <v-divider></v-divider>
                            </template>

                            <template v-slot:item.operation_date="{item}">
                                <span>{{ item.operation_date | moment('DD/MM/YYYY') }}</span>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import OfficeLogin from '../../../components/auth/OfficeLogin';

import InventoryApi from '../../../apis/Inventories';

export default {
    name: 'ReportConsumption',
    components: {
        OfficeLogin
    },
    data () {
        return {
            itemsBreadcrumbs: [
                {
                    text: 'Reportes',
                    disabled: true,
                    href: '',
                }
            ],
            headers: [
                { text: 'FECHA', value: 'operation_date', sortable: false },
                { text: 'ÁREA', value: 'area_name', sortable: false},
                { text: 'PRODUCTO', value: 'name', sortable: false},
                { text: 'MARCA', value: 'brand_name', sortable: false },
                { text: 'SKU', value: 'sku', sortable: false },
                { text: 'CANTIDAD', value: 'total', sortable: false },
            ],
            processing: false,
            menuFrom: false,
            menuUntil: false,            
            filters: {
                type: 'SALIDA',
                subtype: 'CONSUMO INTERNO',
                from: this.$moment().format('YYYY-MM-DD'),
                until: this.$moment().format('YYYY-MM-DD'),
                product_id: null,
                area_id: null,
                paginated: true,
                itemsPerPage: 10
            },
            isLoading: false,
            search_product: null,
            products: [],
            options:{},
            loading: false,
            dataTable: {page: 1},
            pageSelected: 1,
            perPage: 10,
        }
    },
    computed: {
        ...mapState('areas', [
            'areas'
        ]),
        ...mapState('report-kardex', [
            'movements',
            'file_name',
            'paginated',
        ]),
    },
    watch: {
        async search_product (value) {
            if (value == null || value == undefined || value.length == 0) {
                return;
            }

            if (this.products.length > 0) {
                return;
            }

            if (this.isLoading) return

            this.isLoading = true
            this.loading=true
            let filters = {
                description: value
            }
            InventoryApi.getProductsFromOffice(filters)
                .then( response => {
                    this.products = response.data;
                })
                .catch ( error => {
                    console.log(error);
                })
                .finally( () => {this.isLoading = false;this.loading=false});
        },
        options: {
            handler() {
                console.log(this.options)
                this.perPage = this.options.itemsPerPage
                this.pageSelected = this.options.page
                this.loading=true
                const search = {...this.filters,perPage:this.perPage,page:this.pageSelected}
                this.getKardexMovements(search).finally(()=>this.loading=false);

            },
            deep: true,
        }
    },
    methods: {
        ...mapActions('areas', [
            'getAreas'
        ]),
        ...mapActions('inventories', [
            'getProductsFromOffice',
        ]),
        ...mapActions('report-kardex', [
            'getKardexMovements',
            'setMovements',
            'downloadConsumption'
        ]),
        searchMovement () {
            this.getKardexMovements(this.filters).finally(()=>this.loading=false);
        },
        async downloadMovement () {            
            this.processing = true;
            let filters_data = Object.assign({}, this.filters);
            await this.downloadConsumption(filters_data);
            this.processing = false;
            if (this.file_name) {
                let url = process.env.VUE_APP_API_URL_BASE + '/' + this.file_name;
                window.open(url);
            }      
        }
    },
    created () {
        this.searchMovement();
        this.getAreas();
    },
    destroyed () {
        this.setMovements([]);
    }
}
</script>

<style scoped>

</style>